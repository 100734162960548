import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import itGraph from "src/images/programming-graph.png"
import webstorm from "src/images/webstorm-prod.png"
import mockup_apps from "src/images/pages/it/mockup-apps.jpg"
import { connect } from "react-redux"

const It = ({isMobile}) => (
  <Layout>
    <SEO title="Zaawansowane Storny WWW, Systemy Firmowe, Aplikacje mobilne - Departament Reklamy"
         keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={5} iconBg={divisions.it.icon}/>

    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "Dział IT",
        sitemap: [
          { title: "Zaawansowane Strony WWW", id: "zaaw-strony-www" },
          { title: "Systemy firmowe", id: "issas" },
          { title: "Aplikacje mobilne", id: "aplikacje-mobilne" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "010011 - być albo nie być",
        img: itGraph,
        texts: {
          1: {
            title: "Webdesign",
            desc: "Zrobimy dla Ciebie stronę idealnie dopasowaną do Twoich potrzeb. Estetyka, " +
            "prostota oraz łatwość w obsłudze jest dla nas najważniejsza.",
          },
          2: {
            title: "Idealne dopasowanie",
            desc: "Nasze produkty zawsze są przemyślane i zaprojektowane tak aby wszystko było możliwie najbardziej " +
            "intuicyjne i proste w obsłudze. Ogromną uwagę przywiązujemy do atrakcyjnej oraz ciekawej szaty graficznej.",
          },
        },
      }}/>




      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="zaaw-strony-www">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + webstorm + ")",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Zaawansowane Strony WWW</div>
          <div className={subpageStyles.subtitle}>Prosta wizytówka to za mało?</div>
          <div className={subpageStyles.text}>Tak się składa że rozumiemy to doskonale. Witryny ze specjalnymi panelami dla zalogowanych osób to nasza specjalność.</div>
          <div className={subpageStyles.subtitle}>Bezpieczeństwo oraz najnowsze technologie</div>
          <div className={subpageStyles.text}>Każdy projekt realizujemy w oparciu o najnowsze i najskuteczniejsze metody, dzięki czemu witryna nie tylko dobrze wygląda ale również dobrze działa.</div>
        </div>
      </div>




      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}>
      </div>



      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="aplikacje-mobilne">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + mockup_apps + ")",
          backgroundPosition: "center",
          backgroundSize: "auto 100%",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Aplikacje Mobilne</div>
          <div className={subpageStyles.subtitle}>Komfort użytkowania</div>
          <div className={subpageStyles.text}>Dostarcz swoim klientom specjalne funkcje w najlepszym wydaniu.</div>
          <div className={subpageStyles.subtitle}>Wydajność</div>
          <div className={subpageStyles.text}>Każdy projekt realizujemy w oparciu o najnowsze i najskuteczniejsze metody, dzięki czemu witryna nie tylko dobrze wygląda ale również dobrze działa.</div>
        </div>
      </div>



      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}>
      </div>



      <PriceList id="cennik" content={{
        items: [
          { title: "Portal internetowy", desc: "System logowania, zarządzenie treścią", price: "3000 zł", prefix: "od" },
          { title: "Sklep interentowy", desc: "", price: "3500 zł", prefix: "od" },
          { title: "Aplikacja mobilna", desc: "wycena indywidualna", price: "- zł", prefix: "od" },
          { title: "System firmowy", desc: "wycena indywidualna", price: "- zł", prefix: "od" },
        ],
        suggestions: [divisions.website],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(It)

